import React from "react"
import { graphql } from "gatsby"
import Image, { ImageProps } from "../components/Image"
import convert from "../utils/convert"

type ProsjektetPageTemplateProps = {
  start: {
    image: ImageProps,
    title: string,
    text: string,
    videoSrc: string
  },
  partnere: {
    title: string,
    text: string,
    image: ImageProps
  }[],
  design: {
    gallery: ImageProps[],
    image: ImageProps,
    title: string,
    ingress: string,
    text: string
  }
}

const ProsjektetPageTemplate: React.FC<ProsjektetPageTemplateProps> = ({
  start, partnere, design
}) => {
  return (
    <main id="prosjektet-page">
      <Image
        src={start.image.src}
        alt={start.image.alt}
        className="h-screen-66 xl:h-auto object-cover object-center mx-auto w-full"
        loading="eager"
        fadeIn={false}
      />

      <section
        id="start"
        className="py-16 sm:py-8"
      >
        <div className="mx-auto w-full max-w-3xl text-center">
          <h1 className="title">
            {start.title}
          </h1>

          <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px mx-auto" />

          <div
            className="font-semibold text-alt markdown max-w-xl mx-auto"
            dangerouslySetInnerHTML={{
              __html: convert(start.text)
            }}
          />
        </div>

        <div className="mx-auto w-full max-w-1920px mt-32 sm:mt-16">
          <figure style={{
            height: "0",
            position: "relative",
            paddingBottom: "56.25%"
          }}>
            <iframe
              id="video"
              className="absolute pin-r pin-t w-full h-full bg-dark"
              src={start.videoSrc}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </figure>
        </div>
      </section>

      <section
        id="partners"
        className="pt-16 sm:pt-8"
      >
        <div className="mx-auto w-full max-w-1920px">
          {partnere.map(({ title, text, image }, index) => (
            <div
              key={index}
              className={`flex items-center justify-between pb-16 ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"} md:items-stretch md:flex-col md:pb-8`}
            >
              <div className="flex-1">
                <Image
                  className="w-full object-cover object-center"
                  src={image.src}
                  alt={image.alt}
                />
              </div>

              <div className={`flex-1 p${index % 2 === 0 ? "l" : "r"}-8 md:p-0 md:mt-8`}>
                <div className="mx-auto w-full max-w-lg px-10 lg:px-0 md:max-w-full sm:text-center">
                  <h2
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: convert(title)
                    }}
                  />

                  <hr className="my-8 w-8 lg:my-5 pt-px bg-alt h-px text-left sm:mx-auto sm:text-center" />

                  <div
                    className="markdown sm:mx-auto xs:text-left pr-16 lg:pr-0"
                    dangerouslySetInnerHTML={{
                      __html: convert(text)
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section
        id="design"
        className="pt-16 pb-32 sm:pt-8 sm:pb-16"
      >
        <div className="grid grid-columns-4 grid-gap-8 lg:grid-gap-4 sm:grid-columns-2">
          {design.gallery.map((image, index) => (
            <div
              key={index}
              className={`
                ${index === 0 ? "row-span-3 sm:row-span-2" : ""}
                ${index === 2 ? "row-span-2 sm:row-span-1" : ""}
                ${index === 0 ? "col-span-3 sm:col-span-2" : ""}
              `}
            >
              <Image
                src={image.src}
                alt={image.alt}
                className="w-full object-cover object-center h-full"
              />
            </div>
          ))}
        </div>

        <div className="mx-auto w-full max-w-1920px mt-16 flex items-center justify-between md:mt-4 md:items-stretch md:flex-col-reverse">
          <div className="flex-1 pr-8 md:pr-0 md:pt-16">
            <div className="mx-auto w-full max-w-lg px-10 lg:px-0 sm:text-center">
              <h2 className="subtitle">
                {design.title}
              </h2>

              <hr className="my-8 w-8 sm:my-5 pt-px bg-alt h-px text-left sm:mx-auto sm:text-center" />

              <div className="markdown sm:mx-auto xs:text-left">
                <div
                  className="font-semibold mb-8"
                  dangerouslySetInnerHTML={{
                    __html: convert(design.ingress)
                  }}
                />

                <div
                  dangerouslySetInnerHTML={{
                    __html: convert(design.text)
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex-1 lg:hidden md:block">
            <Image
              className="w-full object-cover object-center"
              src={design.image.src}
              alt={design.image.alt}
            />
          </div>
        </div>

      </section>
    </main>
  )
}

interface ProsjektetPageProps {
  data: {
    markdownRemark: {
      frontmatter: ProsjektetPageTemplateProps
    }
  }
}

const ProsjektetPage: React.FC<ProsjektetPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <ProsjektetPageTemplate
      start={frontmatter.start}
      partnere={frontmatter.partnere}
      design={frontmatter.design}
    />
  )
}

export default ProsjektetPage

export const prosjektetPageQuery = graphql`
  query ProsjektetPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        start {
          image {
            src
            alt
          }
          title
          text
          videoSrc
        }

        partnere {
          image {
            src
            alt
          }
          title
          text
        }

        design {
          gallery {
            src
            alt
          }
          image {
            src
            alt
          }
          title
          ingress
          text
        }

      }
    }
  }
`
